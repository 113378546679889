@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {



    /* explorer */


    .item {
        @apply px-2 py-1.5 truncate cursor-pointer rounded select-none hover:bg-[#eaeaeb];
    }

    .item-selected {
        @apply !bg-neutral-100 !opacity-100 !grayscale-0;
    }

    .item>span {
        @apply text-sm truncate text-clip;
    }

    /* filter options */

    .filter-options {
        @apply w-32 bg-white shadow rounded text-sm px-1 py-2;
    }

    .filter-options-item {
        @apply flex flex-row items-center space-x-2 cursor-default px-2 py-1 select-none hover:bg-blue-200;
    }


    /* thumbnails */

    .thumb {
        @apply w-44 h-48 mr-5 mb-4;
    }

    .thumb-dragging {
        @apply !fixed z-50 left-0 top-0 opacity-80 pointer-events-none;
    }


    /* toolbar */

    .toolbar {
        @apply relative h-11 mb-1 p-px bg-slate-800 select-none flex flex-row items-center;
    }


    /* props */


    .prop {
        @apply flex-1 h-full border-r border-r-slate-600 flex flex-row items-center;

    }



    /* context menu */


    .context-menu {
        @apply min-w-28 p-1 bg-slate-800 shadow;
    }

    .context-menu-item {
        @apply relative px-2 py-1 text-sm text-white select-none cursor-default hover:bg-blue-500 flex flex-row items-center;
    }

    .context-menu-item span {
        @apply first-letter:uppercase;
    }

    .context-menu-item.disabled {
        @apply text-slate-600 pointer-events-none;
    }

    .context-menu-item.delimiter {
        @apply border-b border-b-slate-700;
    }

    /* fix content menu does not work with drop-shadow */

    .shadow-md-bg [data-target='bgimage'] {
        @apply shadow-md;
    }

    .shadow-xl-bg [data-target='bgimage'] {
        @apply shadow-xl;
    }


    /* general */

    img {
        @apply pointer-events-none;
    }


    .filter-red {
        filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg) brightness(91%) contrast(135%);
    }

    .filter-green {
        filter: brightness(0) saturate(100%) invert(42%) sepia(84%) saturate(1898%) hue-rotate(88deg) brightness(108%) contrast(113%);
    }

    .filter-black {
        filter: brightness(0) saturate(100%) invert(0%) sepia(94%) saturate(7485%) hue-rotate(63deg) brightness(92%) contrast(96%);
    }

    .filter-white {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(355deg) brightness(106%) contrast(102%);
    }

    .filter-neutral {
        filter: brightness(0) saturate(100%) invert(26%) sepia(40%) saturate(671%) hue-rotate(198deg) brightness(97%) contrast(81%);
    }

    .filter-orange {
        filter: brightness(0) saturate(100%) invert(48%) sepia(59%) saturate(2925%) hue-rotate(357deg) brightness(100%) contrast(93%);
    }

    .filter-blue {
        filter: brightness(0) saturate(100%) invert(54%) sepia(60%) saturate(5170%) hue-rotate(202deg) brightness(98%) contrast(97%);
    }

    .filter-blue-2 {
        filter: brightness(0) saturate(100%) invert(53%) sepia(93%) saturate(589%) hue-rotate(192deg) brightness(99%) contrast(94%);
    }



}